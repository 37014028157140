import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

import Layout from '../components/Layout'
import TitleImg from '../../static/img/RedBankTitle.png'
import LogoImg from '../../static/img/Logo Circle.png'
import CalendarImg from '../../static/img/Calendar Circle.png'
import LocationImg from '../../static/img/Location Circle.png'
import BeliefsImg from '../../static/img/Beliefs.png'
import EventsImg from '../../static/img/Events.png'
import LiveImg from '../../static/img/Live.png'
import PrayerImg from '../../static/img/Prayer.png'
import YouthImg from '../../static/img/Youth.png'
import ContactImg from '../../static/img/Contact.png'

export const IndexPageTemplate = ({
  backgroundimage,
  welcometitle,
  welcomeinfo,
  pastorimage,
  pastorinfo,
  weeklycalendar
}) => (
  <div>
    <div
      className="full-width-image margin-top-0"
      style={{
        backgroundImage: `url(${
          !!backgroundimage.childImageSharp ? backgroundimage.childImageSharp.fluid.src : backgroundimage
        })`,
      }}
    >
      <div
        style={{
          display: 'flex',
          lineHeight: '1',
          justifyContent: 'space-around',
          alignItems: 'left',
          flexDirection: 'column',
        }}
      >
        <img src={TitleImg} alt="Title" style={{ height: '300px' }}/>
      </div>
    </div>

    {/* WELCOME / UPDATE BOX */}
    {/* Background */}
    <div
      className="index-box margin-top-0"
      style={{
        backgroundColor: '#D63340',
        display: 'flex',
        justifyContent: 'space-around'
      }}
    >
      {/* Content Box */}
      <div className="container" style={{display: 'flex', marginTop: '20px', marginBottom: '20px'}}>

        {/* Logo Image */}
        <img src={LogoImg} alt="Logo" style={{ 
          height: '200px',
          margin: '30px',
          // borderRadius: '50%',
          // boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
          }} 
        />

        {/* Google Fonts Import */}
        <link
          href="https://fonts.googleapis.com/css2?family=IM+Fell+English&display=swap"
          rel="stylesheet"
        ></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Crimson+Text&family=Poppins:wght@300&display=swap"
          rel="stylesheet"
        ></link>

        {/* Text */}
        <div className="boxText" style={{margin: '30px'}}>

          {/* Title */}
          <h1 className="title" 
            style={{ 
              fontSize: '2.4em',
              color: 'white',
              fontFamily: 'IM Fell English'
            }}
          >
            {/* A Place You Can Call Home */}
            {welcometitle}
          </h1>

          {/* Information */}
          <p style={{
              fontSize: '1em',
              lineHeight: '2em',
              letterSpacing: '0.06em',
              fontFamily: 'Poppins',
              color: 'white',
            }}
          >
            {/* This is a section of the website where content that can be edited will go. Most of the time, it will contain an introduction and welcome, but if the church has an update or alert, that can go here instead! Normally the title will be the slogan/mission of the church, and the content will be something like "Here at Red Bank Baptist...", but in an update/alert, the title will say something like "New Mask Policy!" or "Last Chance to Sign Up for ___!", and the information below it will explain the update. */}
            {welcomeinfo}
          </p>
        </div>

      </div>
    </div>

    {/* MEET THE PASTOR */}
    {/* Background */}
    <div
      className="index-box margin-top-0"
      style={{
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'space-around'
      }}
    >
      {/* Content Box */}
      <div className="container" style={{ display: 'flex', marginTop: '20px', marginBottom: '20px' }}>

        {/* Text */}
        <div className="boxText" style={{ margin: '30px' }}>

          {/* Title */}
          <h1 className="title"
            style={{
              fontSize: '2.4em',
              color: '#D63340',
              fontFamily: 'IM Fell English'
            }}
          >
            Meet the Pastor
          </h1>

          {/* Information */}
          <p
            style={{
              fontSize: '1em',
              lineHeight: '2em',
              letterSpacing: '0.06em',
              fontFamily: 'Poppins',
              color: '#D63340',
              fontWeight: 'bold'
            }}
          >
            {/* This is a section of the website where content can also be edited, in case of a new pastor. It will have brief info about the current pastor. */}
            {pastorinfo}
          </p>
        </div>

        {/* Pastor Image */}
        <img src={`${!!pastorimage.childImageSharp ? pastorimage.childImageSharp.fluid.src : pastorimage}`} alt="Pastor Image"
          style={{
            height: '200px',
            margin: '30px',
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
          }}
        />
        {/* <img src={pastorimage} alt="PastorImage" style={{
          height: '200px',
          margin: '30px',
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
          // alignItems: 'left',
          // marginLeft: '-300px'
        }}
        /> */}
      </div>
    </div>

    {/* A WEEK WITH {CHURCH} */}
    {/* Background */}
    <div
      className="index-box margin-top-0"
      style={{
        backgroundColor: '#D63340',
        display: 'flex',
        justifyContent: 'space-around'
      }}
    >
      {/* Content Box */}
      <div className="container" style={{ display: 'flex', marginTop: '20px', marginBottom: '20px' }}>

        {/* Calendar Image */}
        <img src={CalendarImg} alt="Calendar" style={{
          height: '200px',
          margin: '30px',
          // borderRadius: '50%',
          // boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
        }}
        />

        {/* Text */}
        <div style={{ margin: '30px' }}>

          {/* Title */}
          <h1 className="title"
            style={{
              fontSize: '2.4em',
              color: 'white',
              fontFamily: 'IM Fell English'
            }}
          >
            A Week with RBBC
          </h1>

          {/* Information */}
          <p
            style={{
              fontSize: '1.1em',
              lineHeight: '2em',
              letterSpacing: '0.06em',
              fontFamily: 'Poppins',
              color: 'white',
            }}
          >
            {weeklycalendar.map((item) => (
              <div>
                <b>{item.weekday + ', '}</b>
                {item.time + ': '}
                <i>{item.event}</i>
                <br />
              </div>
            ))}
          </p>
        </div>
      </div>
    </div>

    {/* WHERE TO FIND US */}
    {/* Background */}
    <div
      className="index-box margin-top-0"
      style={{
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'space-around',
        marginTop: '30px',
        // marginBottom: '30px'
      }}
    >
      {/* Content Box */}
      <div className="container" style={{ display: 'flex', marginTop: '40px', marginBottom: '20px' }}>

        {/* Text */}
        <div style={{ textAlign: 'center', margin: '30px' }}>

          {/* Title */}
          <h1 className="title"
            style={{

              fontSize: '2.4em',
              color: '#D63340',
              fontFamily: 'IM Fell English'
            }}
          >
            Where to Find Us
          </h1>
          {/* Location Marker Image */}
          <img src={LocationImg} alt="Logo" style={{
            height: '200px',
          }}
          />
        </div>
        
        {/* Google Maps */}
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d97777.2926899342!2d-75.93699371100739!3d37.44028235573385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89ba482d9cdd223f%3A0x35825455810669a9!2sRed%20Bank%20Baptist%20Church!5e0!3m2!1sen!2sus!4v1631819840238!5m2!1sen!2sus"
          style={{
            allowfullscreen: 'true',
            loading: 'lazy',
            width: '100%',
            height: '400px',
            border: '0',
            marginLeft: '30px',
            marginRight: '30px'
          }}
        />
      </div>
    </div>

    <section className="section section--gradient">

      <div className="container">
        
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            // width: '70%'
          }}
        >

          <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center'}}>
            <Link to="/beliefs" title="Beliefs">
              <img className="link-button" src={BeliefsImg} alt="Beliefs" style={{ maxHeight: '160px', minWidth: '160px', margin: '6px'}} />
            </Link>
            <Link to="/events" title="Events">
              <img className="link-button" src={EventsImg} alt="Events" style={{ maxHeight: '160px', minWidth: '160px', margin: '6px'}}/>
            </Link>
            <Link to="/livestream" title="Live">
              <img className="link-button" src={LiveImg} alt="Live" style={{ maxHeight: '160px', minWidth: '160px', margin: '6px'}}/>
            </Link>
            <Link to="/prayer" title="Prayer">
              <img className="link-button" src={PrayerImg} alt="Prayer" style={{ maxHeight: '160px', minWidth: '160px', margin: '6px'}}/>
            </Link>
            <Link to="/youth" title="Youth">
              <img className="link-button" src={YouthImg} alt="Youth" style={{ maxHeight: '160px', minWidth: '160px', margin: '6px'}}/>
            </Link>
            <Link to="/contact" title="Contact Us">
              <img className="link-button" src={ContactImg} alt="Contact" style={{ maxHeight: '160px', minWidth: '160px', margin: '6px'}} />
            </Link>
          </div>
        </div>
      </div>
    </section>
  </div>
)

IndexPageTemplate.propTypes = {
  backgroundimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  welcometitle: PropTypes.string,
  welcomeinfo: PropTypes.string,
  pastorimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  pastorinfo: PropTypes.string,
  weeklycalendar: PropTypes.array
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        backgroundimage={frontmatter.backgroundimage}
        welcometitle={frontmatter.welcometitle}
        welcomeinfo={frontmatter.welcomeinfo}
        pastorimage={frontmatter.pastorimage}
        pastorinfo={frontmatter.pastorinfo}
        weeklycalendar={frontmatter.weeklycalendar}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        backgroundimage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        welcometitle
        welcomeinfo
        pastorimage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        pastorinfo
        weeklycalendar {
          weekday
          time
          event
        }
      }
    }
  }
`
